import React from 'react';
import Modal from 'react-modal';
import { motion, AnimatePresence } from 'framer-motion';
import { IoClose } from 'react-icons/io5';

Modal.setAppElement('#root');

const CourseModal = ({ isOpen, onClose, course }) => {
  if (!course) return null;

  return (
    <AnimatePresence>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
        className="relative bg-white rounded-lg w-full max-w-4xl mx-4 my-8 p-4 md:p-6 overflow-y-auto max-h-[90vh]"
        style={{
          overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
        }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="w-full"
        >
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          >
            <IoClose className="w-6 h-6" />
          </button>

          <div className="space-y-6">
            <h2 className="text-2xl md:text-3xl font-bold text-gray-900 text-center md:text-left">{course.title}</h2>
            <p className="text-gray-600">{course.fullDescription}</p>

            <div>
              <h3 className="text-xl font-semibold mb-2">Requisitos:</h3>
              <ul className="list-disc list-inside space-y-1">
                {course.requirements.map((req, index) => (
                  <li key={index} className="text-gray-600">{req}</li>
                ))}
              </ul>
            </div>

            <div>
              <h3 className="text-xl font-semibold mb-2">Lo que aprenderás:</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {course.learningPoints.map((point, index) => (
                  <div key={index} className="p-4 bg-gray-50 rounded-lg">
                    <h4 className="font-semibold text-gray-900">{point.title}</h4>
                    <p className="text-gray-600">{point.description}</p>
                  </div>
                ))}
              </div>
            </div>

            {course.sections && (
              <div>
                <h3 className="text-xl font-semibold mb-2">Contenido del curso:</h3>
                <div className="space-y-4">
                  {course.sections.map((section, index) => (
                    <div key={index} className="p-4 bg-gray-50 rounded-lg">
                      <h4 className="font-semibold text-gray-900">{section.title}</h4>
                      {section.content.map((item, i) => (
                        <p key={i} className="text-gray-600">{item}</p>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {course.pricing && (
              <div>
                <h3 className="text-xl font-semibold mb-2">Precios:</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {course.pricing.map((plan, index) => (
                    <div key={index} className="p-4 bg-gray-50 rounded-lg">
                      <h4 className="font-semibold text-gray-900">{plan.name}</h4>
                      <p className="text-gray-600">{plan.hours}</p>
                      <p className="text-green-600 font-bold">{plan.price}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {course.includes && (
              <div>
                <h3 className="text-xl font-semibold mb-2">Este curso incluye:</h3>
                <ul className="list-disc list-inside space-y-1">
                  {course.includes.map((item, index) => (
                    <li key={index} className="text-gray-600">{item}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </motion.div>
      </Modal>
    </AnimatePresence>
  );
};

export default CourseModal;
