import React, { useState } from 'react';
import { coursesData } from '../data/CoursesData';
import ItemCourse from './ItemCourse';
import CourseModal from './CourseModal';

const CoursesSection = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCourseClick = (course) => {
    setSelectedCourse(course);
    setIsModalOpen(true);
  };

  return (
    <section id="courses" className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold mb-8">Clases Virtuales</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {coursesData.map((course) => (
            <ItemCourse
              key={course.id}
              course={course}
              onClick={() => handleCourseClick(course)}
            />
          ))}
        </div>
      </div>

      <CourseModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        course={selectedCourse}
      />
    </section>
  );
};

export default CoursesSection;