import React from 'react';
import { motion } from 'framer-motion';

const ItemCourse = ({ course, onClick }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      className="relative h-64 rounded-lg overflow-hidden cursor-pointer"
      onClick={onClick}
    >
      {/* Imagen de fondo */}
      <div 
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${course.backgroundImage})` }}
      />
      
      {/* Overlay semitransparente */}
      <div className="absolute inset-0 bg-orange-400 bg-opacity-40" />
      
      {/* Contenido */}
      <div className="relative h-full flex flex-col justify-center items-center text-center p-6">
        <h3 className="text-2xl font-bold text-white mb-2">
          {course.title}
        </h3>
        <p className="text-white mb-4">
          {course.shortDescription}
        </p>
        <button
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-6 rounded-md transition-colors duration-200"
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          Ver Más
        </button>
      </div>
    </motion.div>
  );
};

export default ItemCourse;