// src/data/coursesData.js
import junior from "../assets/junior.png"
import family from "../assets/family.png"

export const coursesData = [
    {
      id: 1,
      title: "SCRATCH FAMILY",
      shortDescription: "para todos los miembros de la familia",
      backgroundImage: junior, // Asegúrate de tener esta imagen en tu carpeta public
      requirements: ["PC", "Internet", "Celular"],
      fullDescription: "La programación es para todos los miembros de la familia; ¡family digital! donde todos pueden ser creadores digitales. En este curso, abrimos la puerta al fascinante mundo de la codificación de una manera lúdica y envolvente, adaptada especialmente para mentes curiosas y con ganas de aprender.",
      learningPoints: [
        {
          title: "Primeros Pasos en la Programación",
          description: "Conoce los conceptos básicos del código y cómo las computadoras entienden las instrucciones."
        },
        {
          title: "Jugando con Código",
          description: "A través de plataformas interactivas y juegos, los niños aprenderán a programar mientras se divierten."
        },
        {
          title: "Creación de Proyectos Propios",
          description: "Desde animaciones hasta pequeños videojuegos."
        }
      ]
    },
    {
      id: 2,
      title: "SCRATCH JUNIOR",
      shortDescription: "conceptos basicos de la programacion",
      backgroundImage: family, // Asegúrate de tener esta imagen en tu carpeta public
      requirements: ["PC", "Internet", "Celular"],
      fullDescription: "En este curso de SCRATCH JUNIOR, los participantes tendrán la oportunidad de aprender los conceptos básicos de la programación de una manera divertida e interactiva. Utilizando la plataforma Scratch, los niños y niñas explorarán la creación de personajes, escenarios y animaciones sencillas, además de enfrentar desafíos y desarrollar proyectos finales.",
      learningPoints: [
        {
          title: "Primeros Pasos en la Plataforma con SCRATCH",
          description: "Conoce la interfaz de la plataforma y cómo SCRATCH entiende las instrucciones."
        },
        {
          title: "Jugando con la simbología de SCRATCH",
          description: "A través de la plataforma interactiva y su simbología, los niños y niñas aprenderán a programar mientras se divierten."
        },
        {
          title: "Creación de su propio Proyectos",
          description: "De animaciones."
        }
      ],
      whatYouWillLearn: [
        "Los elementos fundamentales de programación para niños",
        "Dibujaras con programación"
      ],
      sections: [
        {
          title: "INTRODUCCIÓN",
          content: []
        },
        {
          title: "SECCIÓN 1",
          content: [
            "Conceptos básicos de programación como:",
            "- secuencia",
            "- repetición",
            "- toma de decisiones"
          ]
        },
        {
          title: "SECCIÓN 2",
          content: [
            "Cómo crear:",
            "- personajes",
            "- escenarios",
            "- objetos interactivos",
            "Cómo añadir:",
            "- movimiento",
            "- sonido",
            "- animación",
            "Cómo crear juegos sencillos:",
            "- con carreras de obstáculos"
          ]
        },
        {
          title: "SECCIÓN DE CIERRE",
          content: []
        }
      ],
      pricing: [
        {
          name: "Plan A",
          hours: "2 hr/semanales",
          price: "$ 10.000,00"
        },
        {
          name: "Plan B -solo los sabados-",
          hours: "2 hr/semanales",
          price: "$ 15.000,00"
        }
      ],
      includes: [
        "Recursos descargables",
        "Certificado de finalización"
      ]
    }
  ];