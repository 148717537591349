// src/components/InicioSection.js
import React from 'react';
import { Link } from 'react-scroll';
import fondo from "../assets/fondoinicio.svg"

const InicioSection = () => {
  return (
    <section id="inicio" className="bg-cover bg-center h-screen" style={{ backgroundImage: `url(${fondo})` }}>
      <div className="flex items-center justify-center h-full bg-black bg-opacity-50">
        <div className="text-center text-white">
          <h1 className="md:text-7xl text-2xl font-bold md:mb-4 font-serif">Clases</h1>
          <h1 className="md:text-7xl text-2xl text-orange-400 font-bold md:mb-4 mb-10 font-serif">Virtuales</h1>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="bg-green-600 cursor-pointer hover:bg-green-700 transition-all ease-in-out text-white font-bold py-4 px-5 mt-5 rounded"
          >
            Contactar a Profe Pernalete
          </Link>
        </div>
      </div>
    </section>
  );
};

export default InicioSection;