// src/components/Footer.js
import React from 'react';
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col sm:flex-row items-center justify-between text-center sm:text-left">
          <p className="text-sm mb-2 sm:mb-0">
            &copy; copyright 2024 | Created by Katherine Contreras
          </p>
          <a
            href="https://github.com/katherine-j-c-s"
            className="text-sm cursor-pointer mb-2 sm:mb-0"
          >
            https://github.com/katherine-j-c-s
          </a>
          <div className="flex justify-center space-x-4">
            <a
              href="https://facebook.com"
              className="bg-black text-white rounded-full p-2 hover:bg-gray-700"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://instagram.com"
              className="bg-black text-white rounded-full p-2 hover:bg-gray-700"
            >
              <FaInstagram />
            </a>
            <a
              href="https://twitter.com"
              className="bg-black text-white rounded-full p-2 hover:bg-gray-700"
            >
              <FaTwitter />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
