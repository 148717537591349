import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { motion, AnimatePresence } from 'framer-motion';
import logo from "../assets/logo.svg";
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import { RiMenu4Line } from 'react-icons/ri';
import { IoClose } from 'react-icons/io5';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const menuItems = [
    { to: "inicio", label: "Inicio" },
    { to: "about", label: "Sobre Nosotros" },
    { to: "courses", label: "Clases Virtuales" },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-orange-400 bg-opacity-25 shadow-md fixed top-0 w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex relative z-20 items-center justify-between">
          <div className="flex-shrink-0">
            <a href="/">
              <img src={logo} alt="Asesorías Pernalete" className="h-8" />
            </a>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:block">
            <div className="ml-10 flex items-center space-x-4">
              {menuItems.map((item) => (
                <Link
                  key={item.to}
                  to={item.to}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="text-white hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium cursor-pointer"
                >
                  {item.label}
                </Link>
              ))}
              <div className="social-icons flex max-w-2xl justify-around">
                <a href="https://facebook.com" className="social-icon bg-black text-white rounded-full p-2 m-2 facebook">
                  <FaFacebookF />
                </a>
                <a href="https://instagram.com" className="social-icon bg-black text-white rounded-full p-2 m-2  instagram">
                  <FaInstagram />
                </a>
                <a href="https://twitter.com" className="social-icon bg-black text-white rounded-full p-2 m-2  twitter">
                  <FaTwitter />
                </a>
              </div>
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-md cursor-pointer"
              >
                Contacto
              </Link>
            </div>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-white hover:text-gray-800 p-2"
            >
              {isOpen ? (
                <IoClose className="h-6 w-6" />
              ) : (
                <RiMenu4Line className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="md:hidden bg-white shadow-lg"
          >
            <div className="px-2 pt-2 pb-3 space-y-1">
              {menuItems.map((item) => (
                <Link
                  key={item.to}
                  to={item.to}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="text-gray-500 hover:text-gray-800 block px-3 py-2 rounded-md text-base font-medium cursor-pointer"
                  onClick={() => setIsOpen(false)}
                >
                  {item.label}
                </Link>
              ))}
              <div className="social-icons flex max-w-2xl justify-left">
                <a href="https://facebook.com" className="social-icon bg-black text-white rounded-full p-2 m-2 facebook">
                  <FaFacebookF />
                </a>
                <a href="https://instagram.com" className="social-icon bg-black text-white rounded-full p-2 m-2  instagram">
                  <FaInstagram />
                </a>
                <a href="https://twitter.com" className="social-icon bg-black text-white rounded-full p-2 m-2  twitter">
                  <FaTwitter />
                </a>
              </div>
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="bg-green-500 hover:bg-green-600 text-white font-bold block w-24 px-3 py-2 rounded-md cursor-pointer"
                onClick={() => setIsOpen(false)}
              >
                Contacto
              </Link>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;